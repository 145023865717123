import { Directive, HostBinding } from '@angular/core';

const base =
  'base__select block w-full rounded-lg border-0 pl-3 pr-10 text-mango-black17 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-mango text-base sm:leading-6 input--padding';

@Directive({
  selector: 'select[base-select]',
  standalone: true,
})
export class BaseSelectDirective {
  @HostBinding('class') twClasses = base;

  constructor() {}
}
